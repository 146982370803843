var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col float-right" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-btn", {
                attrs: { label: "출력", icon: "print" },
                on: { btnClicked: _vm.krasPrint },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-tab", {
        ref: "krasActionTab",
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: { param: _vm.param, height: _vm.height },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }