<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <c-btn
          label="출력"
          icon="print"
          @btnClicked="krasPrint" />
      </q-btn-group>
    </div>
    <c-tab
      ref="krasActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :height="height"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'kras-onepage',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: 'onepage',
      editable: true,
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if (this.editable && !this.disabled) {
        numHeight = numHeight - 30
      }
      return numHeight + 'px';
    },
    tabItems() {
      let items = [
        { key: '1', name: 'onepage', icon: 'plagiarism', label: '한눈에 보기', component: () => import(`${'./krasOnePageDetail.vue'}`) },
        { key: '2', name: 'education', icon: 'edit_calendar', label: '관련 교육', component: () => import(`${'@/pages/ram/riskEducation.vue'}`) },
        { key: '3', name: 'status', icon: 'equalizer', label: '통계', component: () => import(`${'./krasStatus.vue'}`) },
      ]
      return items;
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.printUrl = selectConfig.ram.kras.print.url;
      // code setting
      // list setting
    },
    krasPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let excelName = this.assessmentName ? this.assessmentName : this.$comm.getToday();
          let fileOrgNm = 'KRAS_' + excelName + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );

    },
  }
};
</script>